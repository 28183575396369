import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";

import Askus from "../views/Askus.vue";
import Terms from "../views/Terms.vue";
import Cookies from "../views/Cookies.vue";
import Privacypolicy from "../views/Privacypolicy.vue";
import Speechor from "../views/Speechor.vue";
import Successpayment from "../views/Successpayment.vue";
import Failedpayment from "../views/Failedpayment.vue";
import Checkout from "../views/Checkout.vue";
import VideoGenerator from "../views/VideoGenerator.vue";

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
  {
    path: "/",
    name: "home",
    component: Speechor
  },
  {
    path: "/Offer",
    name: "Offer",
    component: Speechor
  },
  {
    path: "/Checkout",
    name: "Checkout",
    component: Checkout
  },
  {
    path: "/Subscription_Video_Transcriber",
    name: "Subscription_Video_Transcriber",
    component: VideoGenerator
  },
  {
    path: "/Askus",
    name: "Askus",
    component: Askus
  },
  {
    path: "/Customersupport",
    name: "Customersupport",
    component: Askus
  },
  {
    path: "/Terms",
    name: "Terms",
    component: Terms
  },
  {
    path: "/Cookies",
    name: "Cookies",
    component: Cookies
  },
  {
    path: "/Privacypolicy",
    name: "Privacypolicy",
    component: Privacypolicy
  },
  {
    path: "/Success-payment",
    name: "Successpayment",
    component: Successpayment
  },

  {
    path: "/Failed-payment",
    name: "Failedpayment",
    component: Failedpayment
  }
  
  
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});



export default router;
