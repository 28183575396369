import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({encodingType: 'rc4', isCompression: false});
ls.removeAll();
import UserService from '../services/user.service';

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    something:true,
    offers:[],
    paddleMonthlyPrices:[],
    paddleAnnuallyPrices:[],
    showHeader:false,
    loading:false,
    savingPaymentStatus:[false,'',''],
  },
  plugins: [
    
    createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
  mutations: {
    SET_SOMETHING(state, something){
      state.something = something;
    },
    SET_PRICE(state, offers){
      state.offers = offers;
    },
    SET_PADDLE_MONTHLY_PRICES(state, paddleMonthlyPrices){
      state.paddleMonthlyPrices = paddleMonthlyPrices;
    },
    SET_PADDLE_ANNUALLY_PRICES(state, paddleAnnuallyPrices){
      state.paddleAnnuallyPrices = paddleAnnuallyPrices;
    },
    SET_BTN_LOAD(state, loading){
      state.loading = loading;
    },
    SET_SAVE_PAYMENT_STATUS(state, savingPaymentStatus){
      state.savingPaymentStatus = savingPaymentStatus;
    }
  },

  actions: {

    async initialize({commit}){
      commit("SET_SOMETHING", false);
      commit("SET_SAVE_PAYMENT_STATUS", [false,'','']);
    },


    async getprice({ commit}){
      commit("SET_SOMETHING", true);
      const priceJson = await UserService.getprice()
                    .then(response => {
                    if (response.data!=='' && response.data.constructor === Object){
                      var reStatus = response.data.response.status;
                        if(reStatus == '300' || reStatus == '301' || reStatus == '302' || reStatus == '114'){
                          return '';
                        } else {
                          return response.data.response.result;
                        }
                    } else {
                      return [];
                    }
                    });
      commit("SET_PRICE", priceJson);
      
    },

    async getPaddlePrices({ commit}, {moduleReference}){
      commit("SET_SOMETHING", true);
      const pricesJson = await UserService.getPaddlePrices()
                    .then(response => {
                    if (response.data!=='' && response.data.constructor === Object){
                      var reStatus = response.data.response.status;
                        if(reStatus == '300' || reStatus == '301' || reStatus == '302'){
                          return [];
                        } else if (reStatus == '200') {
                          return response.data.response.result;
                        } else {
                          return [];
                        }
                    } else {
                      return [];
                    }
                    });
      //filter only Transcriber Prices
      const filteredModulePrices = await pricesJson.pricesArray.filter(obj => obj.name.includes(moduleReference));             
      //get filered available Prices
      let filteredAllPricesByConfig = [];
      if (pricesJson.offerConfig == "Regular"){
        filteredAllPricesByConfig = await filteredModulePrices.filter(obj => obj.name.includes("Regular"));
      }
      if (pricesJson.offerConfig == "Trial"){
        filteredAllPricesByConfig = await filteredModulePrices.filter(obj => !obj.name.includes("Regular"));
      }
      
      const filteredMonthlyJson = await filteredAllPricesByConfig.filter(obj => obj.name.includes('Monthly'));
      const filteredAnnuallyJson = await filteredAllPricesByConfig.filter(obj => obj.name.includes('Annually'));

      commit("SET_PADDLE_MONTHLY_PRICES", filteredMonthlyJson);
      commit("SET_PADDLE_ANNUALLY_PRICES", filteredAnnuallyJson);
      
    },

    async SavePaddleSubscription({commit},{transactionId, email}){
          commit("SET_SAVE_PAYMENT_STATUS", [false,'','']);
          await UserService.SavePaddleSubscription(transactionId, email)
                    .then(response => {
                    
                    if (response.data!=='' && response.data.constructor === Object){
                        var reStatus = response.data.response.status;
                        if(reStatus == '300' || reStatus == '301' || reStatus == '302'){
                          commit("SET_SAVE_PAYMENT_STATUS", [true,'Oops! something went wrong!','danger']);
                        } else if (reStatus == '112') {
                          commit("SET_SAVE_PAYMENT_STATUS", [true, response.data.response.message, 'danger']);
                          //paddle api error 
                        } else if (reStatus == '200') {
                          commit("SET_SAVE_PAYMENT_STATUS", [true, response.data.response.result ,'success']);
                      
                        } else {
                          commit("SET_SAVE_PAYMENT_STATUS", [true,'Oops! something went wrong! try again later or contact us!','danger']);
                     
                        }
                    } else {
                      commit("SET_SAVE_PAYMENT_STATUS", [true,'Oops! something went wrong! try again later or contact us!','danger']);
                
                    }
                    });
    },

    async ordernow({commit},{product, referrer}){
          commit("SET_SOMETHING", true);
          commit("SET_BTN_LOAD", true);
          if (product!=='' && product!==null && product!==undefined){
            const appUrl = await UserService.ordernow(product, referrer)
                    .then(response => {
                    if (response.data!=='' && response.data.constructor === Object){
                        var reStatus = response.data.response.status;
                        if(reStatus == '300' || reStatus == '301' || reStatus == '302'){
                          commit("SET_BTN_LOAD", false);
                          return '';
                        } 
                        else if (reStatus == '111' || reStatus == '104') {
                          commit("SET_BTN_LOAD", false);
                          return '';
                        }
                        else {
                          return response.data.response.result;
                        }
                    } else {
                      commit("SET_BTN_LOAD", false);
                      return '';
                    }
                    });
            if (appUrl !== ''){  
              window.location.replace(appUrl); 
            }
          
          } else {
            commit("SET_BTN_LOAD", false);
          }
                    
    },
    
    async sendMessage({commit}, {name, email, subject, message}){
          commit("SET_SOMETHING", false);
          await UserService.sendMessage(name, email, subject, message)
                    .then(response => {
                    if (response.data!=='' && response.data.constructor === Object){
                        var reStatus = response.data.response.status;
                        if(reStatus == '200'){
                          return Promise.resolve(response);
                        }
                        else {
                          return Promise.reject(response);
                        }
                    } else {
                      return Promise.reject(response);
                      }
                    });       
                 
    },
  },
  
  getters: {
    getPrice(state){
      return state.offers;
    },

    getMonthlyPrices(state){
      return state.paddleMonthlyPrices;
    },

    getAnnuallyPrices(state){
      return state.paddleAnnuallyPrices;
    },
  
    getBtnLoad(state){
      return state.loading;
    },

    getSavingStatus(state){
      return state.savingPaymentStatus;
    },
    
  }
  
});