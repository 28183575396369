<template>
	<b-container>
      <b-row class="justify-content-center">
        <b-col md="8" class="p-0">
        <div class="checkout-container "></div>
      </b-col>
      <b-col md="4" class="d-flex flex-column bg-primary pb-2 mb-2">
        <b-alert class="mt-2" v-if="errorMessage" variant="danger"  show dismissible>{{ errorMessage }}</b-alert>

        <b-alert class="mt-2" v-if="getSavingStatus[0]" :variant="getSavingStatus[2]"  show dismissible>{{ getSavingStatus[1] }}</b-alert>

        <div v-show="!getSavingStatus[0] && isCheckoutCompleted" class="text-center">
          <p>Your Account Setup in Progress! Don't Close this page, Please Wait ...</p>
          <h3><b-icon icon="arrow-clockwise" font-scale="2" animation="spin-pulse" variant="light"></b-icon></h3>  
        </div>

        <b-button v-show="isCheckoutCompleted" :disabled="!getSavingStatus[0]"  @click="onSpeechorLogin" variant="warning" class="mt-2">Login Page</b-button>
        <b-button v-show="isCheckoutCompleted" :disabled="!getSavingStatus[0]"  @click="onBackToPricing" variant="warning" class="mt-2">Back to Pricing</b-button>
        <b-button v-show="isCheckoutCompleted" :disabled="!getSavingStatus[0]"  @click="onContactUs" variant="warning" class="mt-2">Contact Us</b-button>

          <div v-show="!isCheckoutCompleted">
            <h3>Items</h3>
            <b-table
                class="text-light"
                show-empty
                striped
                primary
                small
                stacked="md"
                ref="tableRef"
                :items="itemsArray"
                :fields="fieldsItems" 
              > 

              <template #cell(totalprice)="row">
                <div>${{ row.value }}</div>
              </template>
            </b-table>
            
            <h3>Totals</h3>
            <b-table
                class="text-light"
                show-empty
                striped
                primary
                small
                stacked
                ref="tableRef"
                :items="totalsArray"
                :fields="fieldsTotals" 
              >
              <template #cell(recurringTotal)="row">
                <div>${{ row.value }} (per {{billingInterval}})</div>
              </template>
              <template #cell(discountTotal)="row">
                <div>${{ row.value }}</div>
              </template>
              <template #cell(taxTotal)="row">
                <div>${{ row.value }}</div>
              </template>
              <template #cell(totalToday)="row">
                <div><b>${{ row.value }}</b></div>
              </template>
            </b-table>
          </div>
         
          <b-button v-show="!isCheckoutCompleted" @click="switchPlan" variant="secondary" class="mb-4">Switch to {{ notSelectedPlan }} Plan</b-button>
          <b-button v-show="!isCheckoutCompleted" @click="onCancel" variant="secondary" class="mt-auto">- Cancel -</b-button>

      </b-col>
      </b-row>
	</b-container> 
</template>

<script>
  import { mapGetters } from "vuex";
export default {
 
  data() {
    return {
      paddle: window.Paddle,
      billingCycle: 'month',
      cancelUrl: '',
      redirectUrl: 'https://speechorapp.univerhome.com',
      monthItemsList:[],
      yearItemsList:[],
      isMonthly: true,
      itemsArray: [],
      fieldsItems: [
          { key: 'price', label: 'Package'},
          { key: 'quantity', label: 'Quantity'},
          { key: 'totalprice', label: 'Total'},
        ],
      totalsArray: [],
      fieldsTotals: [
          { key: 'recurringTotal', label: 'Billing cycle charges'},
          { key: 'discountTotal', label: 'Discount'},
          { key: 'taxTotal', label: 'Taxes'},
          { key: 'totalToday', label: 'Total Today'},
        ],
      notSelectedPlan: '',
      billingInterval: '',
      isCheckoutCompleted:false,
      errorMessage:'',
    };
  },

  computed: {
    ...mapGetters(["getSavingStatus"])
  },

  mounted(){

    this.$store.dispatch("initialize");

    this.monthItemsList = [{ priceId: this.$route.query.mp, quantity: 1}];
    this.yearItemsList = [{ priceId: this.$route.query.ap, quantity: 1}];
    this.billingCycle = this.$route.query.bc;
    this.cancelUrl = this.$route.query.curl;

    //enable envirenment for dev sandbox only
    //this.paddle.Environment.set("sandbox");
    //sandbox token
    //token: "test_6e29fe93f736b11a9d7ec625f09" 
 
    const callUpdateTable = (event) => {
      this.errorMessage='';
      this.updateTable(event);
    }

    this.paddle.Initialize({
      token: "live_95a0d264e9d71513759ec1dd23b",
      checkout: {
                  settings: {
                              displayMode: "inline",
                              frameTarget: "checkout-container",
                              frameInitialHeight: "450",
                              frameStyle: "width: 100%; min-width: 312px; background-color: #ffffff; border: none;",
                              variant: 'one-page',
                              showAddDiscounts: true,
                              locale: 'en'
                  }
      },
      eventCallback: callUpdateTable
    }); 

    if (this.billingCycle == 'year'){
      this.openCheckout(this.yearItemsList);
    } else {
      this.openCheckout(this.monthItemsList);
    }
    

  },

  methods:{
    onCancel(){
      this.paddle.Checkout.close();
      window.location.replace(this.cancelUrl);
    },

    onBackToPricing(){
      window.location.replace(this.cancelUrl);
    },

    onSpeechorLogin(){
      window.location.replace(this.redirectUrl);
    },

    onContactUs(){
      this.$router.push("/Askus");
    },

    // open checkout
   openCheckout(items){
      this.paddle.Checkout.open({
        items: items
      });
    },

    switchPlan() {
      this.itemsArray = [];
      this.totalsArray= [];
      let updatedItems = this.isMonthly ? this.yearItemsList : this.monthItemsList;
      this.paddle.Checkout.updateCheckout({
        items: updatedItems
      });
      this.isMonthly = !this.isMonthly;
    },

    updateTable(event) {
      if (!event.name) {
        return;
      }


      //take all customer information from this event and add it to Speechor users table db
      if (event.name == 'checkout.completed') {
        //dispatch save new checkout data
        this.isCheckoutCompleted = true;
        //important! address should be collected by me, the get it from this event.data.customer.address
        setTimeout(() => {
          this.$store.dispatch("SavePaddleSubscription", { transactionId: event.data.transaction_id,
                                                  email: event.data.customer.email});
        }, 7000);

        this.$analytics.fbq.event('SuccessPayment', {currency: "USD", value: 9.99});
        this.$gtag.event('conversion', {
            'send_to': 'AW-710213325/ZJq_CLfs4_sCEM3909IC',
            'value': 9.99,
            'currency': 'USD',
            'transaction_id': ''
        });
         
      }

      if (event.name == 'checkout.payment.failed') {
        //show error detail to the customer
        this.errorMessage='Payment failed! verify if your payment method is active and has enough credit for the checkout amount, then try again!'
      }

      if (event.name == 'checkout.error') {
        //show error detail to the customer
        this.errorMessage= event.error.code + ': ' + event.error.detail;
      } 
      
      const updateDetailsArrayEvents = ['checkout.loaded', 'checkout.updated'];
      if(updateDetailsArrayEvents.includes(event.name)){
        let items = event.data.items;

        this.updateItemsTable(items);
        this.updateSummaryTable(event.data.totals, event.data.recurring_totals);
      }
      
      
    },

    updateItemsTable(items) {
      this.itemsArray = [];
      items.forEach(item => {
        const rObj = {};
        rObj.price = item.price_name;
        rObj.quantity = item.quantity;
        rObj.totalprice = item.recurring_totals.total.toFixed(2);    
        this.itemsArray.push(rObj);
        this.billingInterval = item.billing_cycle.interval;
        this.notSelectedPlan = this.billingInterval == "month" ? "Annually" : "Monthly";
      });

    },

    updateSummaryTable(totals, recurringTotals) {
      this.totalsArray = [];
      const tObj = {};
        tObj.recurringTotal = recurringTotals.subtotal.toFixed(2);
        tObj.discountTotal = totals.discount.toFixed(2);
        tObj.taxTotal =  totals.tax.toFixed(2);
        tObj.totalToday = totals.total.toFixed(2);      
        this.totalsArray.push(tObj);
    },

  },

};
</script>
<style scoped>
</style>